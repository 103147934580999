/*------------------------------------------------------------
// Header
//
//----------------------------------------------------------*/

/* ==================================================================
   Sizes
   ================================================================== */

:root {

  /* Portrait phones */

  /* Landscape phones */      /* iPhone 5 */      /* iPhone 6+ */      /* iPad Mini portrait */       /* iPad Mini landscape */

  /* Grid-guidelines
  http://digibeta.arbetsformedlingen.se/komponenter/gridsystem.html */
}

@media (min-width: 801px) {

  .Header__outerwrap--3YWi6 {
    background: rgb(52, 84, 104);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 20px 30px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .Header__outerwrap--3YWi6 {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }
}

.Header__topwrap--1_cc1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

@media (min-width: 801px) {

  .Header__topwrap--1_cc1 {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    max-width: 900px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .Header__topwrap--1_cc1 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}

.Header__titlewrap--2Tz91 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

@media (min-width: 801px) {

  .Header__titlewrap--2Tz91 {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.Header__titletextwrap--36uZx {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

a.Header__title--3Wcfe {
  color: rgb(255, 255, 255);
  font-size: 22px;
  font-weight: normal;
  text-decoration: none;
}

@media (min-width: 801px) {

  a.Header__title--3Wcfe {
    font-size: 30px;
  }
}

a.Header__title--3Wcfe:hover {
  color: rgb(255, 255, 255);
}

.Header__subtitle--2oF6N {
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: 300;
  margin: 0;
  -ms-flex-preferred-size: 75%;
      flex-basis: 75%;
}

.Header__buttonwrap--1CQ2_ {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  min-width: 81px;
}

.Header__bottom--3JX7r {
  background: rgb(43, 68, 85);
  color: rgb(255, 255, 255);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px;
}

@media (min-width: 801px) {

  .Header__bottom--3JX7r {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    background: rgb(52, 84, 104);
    -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
    padding: 0;
  }
}

.Header__name--xbqgA {
  font-weight: 700;
}
