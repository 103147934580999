/*------------------------------------------------------------
// FormRadio
//
//----------------------------------------------------------*/
/* ==================================================================
   Sizes
   ================================================================== */
:root {

  /* Portrait phones */

  /* Landscape phones */      /* iPhone 5 */      /* iPhone 6+ */      /* iPad Mini portrait */       /* iPad Mini landscape */

  /* Grid-guidelines
  http://digibeta.arbetsformedlingen.se/komponenter/gridsystem.html */
}
@media (max-width: 67em) {
  .FormRadio__inputs--22w3x.FormRadio__spaced--1x6Eh {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
@media (min-width: 801px) {
  .FormRadio__inputs--22w3x.FormRadio__spaced--1x6Eh {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.FormRadio__inputs--22w3x.FormRadio__column--ZUUgI {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.FormRadio__wrapper--3-s4w {
  border-color: rgb(204, 204, 204);
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 10px;
  position: relative
}
.FormRadio__wrapper--3-s4w:hover {
  cursor: pointer;
  background: rgb(249, 249, 249);
}
.FormRadio__wrapper--3-s4w:after {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  content: '';
  z-index: -1;
  border-radius: 5px;
}
.FormRadio__wrapper--3-s4w:not(:last-child) {
  margin-bottom: 5px;
}
.FormRadio__radio--1LOFW {
  display: none;
}
.FormRadio__customRadio--3cIwP {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(51, 51, 51);
  border-radius: 100%;
  cursor: pointer;
  height: 16px;
  margin-right: 10px;
  position: relative;
  width: 16px;
}
.FormRadio__radio--1LOFW:checked + .FormRadio__customRadio--3cIwP:after {
  -webkit-animation: FormRadio__indicate--1XrmF 100ms ease-in-out 1;
          animation: FormRadio__indicate--1XrmF 100ms ease-in-out 1;
  background: rgb(51, 51, 51);
  border-radius: 100%;
  content: '';
  height: 8px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 8px;
}
.FormRadio__formRadio--3buwU {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.FormRadio__label--3GdZc {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  cursor: pointer;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .FormRadio__label--3GdZc {
    white-space: nowrap;
  }
}
.FormRadio__languages--1wJzU.FormRadio__column--ZUUgI {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 801px) {
  .FormRadio__horizontal--1Yic9 {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 80%;
        flex-basis: 80%;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .FormRadio__horizontal--1Yic9 > div {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .FormRadio__horizontal--1Yic9 label {
    margin-bottom: 0;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}
@-webkit-keyframes FormRadio__indicate--1XrmF {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes FormRadio__indicate--1XrmF {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
/*
// Language adjustments
// ------------------------------------------------*/
.rtl .FormRadio__customRadio--3cIwP {
  margin-right: 0;
  margin-left: 10px;
}
