/*------------------------------------------------------------
// CoreLayout
//
//----------------------------------------------------------*/
/* ==================================================================
   Sizes
   ================================================================== */
:root {

  /* Portrait phones */

  /* Landscape phones */      /* iPhone 5 */      /* iPhone 6+ */      /* iPad Mini portrait */       /* iPad Mini landscape */

  /* Grid-guidelines
  http://digibeta.arbetsformedlingen.se/komponenter/gridsystem.html */
}
html,
body {
  background-color: rgb(249, 249, 249);
  height: 100%;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  text-rendering: optimizeLegibility;
  width: 100%;
}
html.rtl body {
  text-align: right;
}
.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}
.root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .root {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 100%;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 992px) {
  .root {
    display: block;
  }
}
/*
// Resets
// ------------------------------------------------*/
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
a {
  color: rgb(22, 22, 178)
}
a:hover,
  a:focus:hover,
  a:visited:hover {
  color: #424ae0;
}
a:visited,
  a:focus {
  color: rgb(22, 22, 178);
}
/*
// Resets for AF CSS
// ------------------------------------------------*/
.App__h1--2Cmne,
h1 {
  max-width: 100%;
}
label {
  font-size: inherit;
  font-weight: inherit;
  margin-bottom: 0;
  margin-bottom: initial;
}
.btn {
  white-space: normal;
}
.btn.btn-full {
  padding: 13px 20px;
  width: 100%;
}
