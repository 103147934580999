/*------------------------------------------------------------
// Icon
//
//----------------------------------------------------------*/
.Icon__icon--YFXFc:focus {
  outline: none;
}
.Icon__icon--YFXFc {
  position: relative;
}
.rtl .Icon__flipped--I7j8g {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.Icon__badge--y-iNY {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgb(126, 193, 61);
  bottom: 9px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: Open sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif;
  font-size: 8px;
  font-weight: 700;
  height: 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 12px;
  position: absolute;
  width: 20px;
}
